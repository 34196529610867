import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Contacts from '../modules/Contacts/Contacts'

const ContactPage = () => (
  <Layout>
    <SEO title="Contact Us" />
    <Contacts />
    <script src="https://platform.linkedin.com/in.js" type="text/javascript"> lang: en_US</script>
  </Layout>
)

export default ContactPage
