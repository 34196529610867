import React, { Component } from "react"

import "./Contact.css"
import Signup from "../Home/signup"
import axios from "axios"
import home_mobile from "../../images/home_mobile.jpg"
import { navigate } from "gatsby"
import { LandingScreen } from "../Home/Styled"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { loginUrl } from "../../config/keys"
import BGIMG0 from "../../images/Home.jpg"
import styled from "styled-components"

class ContactPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      email: "",
      phone: "",
      message: "",
      companyName: "",
      intrestIn: "",
      countries: [],
      success: false,
      country: "",
    }
  }
  componentDidMount() {
    axios
      .get(`https://prod.tekorero.com/korero/api/v2.0/countries`)
      .then((res) => {
        this.setState({ countries: res.data })
      })
      .catch((err) => {})
  }
  changeHandler = (e) => {
    console.log(e.target)
    console.log(e.target.value)
    console.log([e.target.name])
    this.setState({ [e.target.name]: e.target.value })
    console.log(this.state.intrestIn)
  }
  submitHandler = (e) => {
    const {
      email,
      message,
      name,
      phone,
      companyName,
      intrestIn,
      country,
    } = this.state
    console.log(this.state.intrestIn)
    if (!email && !phone) {
      alert("Email or Phone number must be provided")
      // return
    }
    e.preventDefault()
    const data = {
      name: name || "",
      email: email || "",
      phone: phone || "",
      message: message || "",
      companyName: companyName || "",
      intrestIn: intrestIn || "",
      country: country || "",
      website: "www.tekorero.com",
    }
    axios
      .post(`https://appbackend.tekorero.com/api/v2.0/contactUs`, data)
      .then((res) => {
        alert("Thanks for reaching out, we will revert soon")
        navigate("/features")
        this.setState({
          name: "",
          email: "",
          phone: "",
          message: "",
          companyName: "",
          intrestIn: "",
          country: "",
        })
      })
      .catch((err) => {})
  }

  render() {
    const { name, email, phone, message, companyName } = this.props
    return (
      <div>
        <div className="mobile_text">
          We look forward to{" "}
          <span style={{ color: "rgb(0, 191, 255)" }}>HEARING</span> from you!
          {/* <span style={{ color: "rgb(0, 191, 255)" }}> BEST</span> out of your{" "}
          <span style={{ color: "rgb(0, 191, 255)" }}> SALES & OPERATIONS</span>{" "} */}
        </div>
        {/* <div className="contact-banner">
          <div style={{ height: "650px" }}>
            <img src={contact} height="650px"></img>
          </div>
        </div> */}
        <LandingScreen
        // backgroundImage={`url(${BGIMG0})`}
        // mobileBackgroundImage={`url(${home_mobile})`}
        >
          <div className="landing_image_main_box">
            <div className="landing_image_main_box_first_child">
              {/* <div className="image_text_For_landing_page"> */}

              <Box>
                <Header> We look forward to HEARING from you!</Header>
                <br />
                <br />
                <Paragraph>Our Team will be happy to help you. </Paragraph>
              </Box>
              {/* </div> */}

              {/* <div className="button_for_landing_page_in_web">
                <a
                  onClick={(e) => {
                    trackCustomEvent({
                      category: "start your free trial caraousel button ",

                      action: "Click",

                      label: "Start your free trial button in home page",

                      value: 43,
                    })
                  }}
                  className="banner_buttons"
                  href={`${loginUrl}/register/Option`}
                  target="_blank"
                >
                  START YOUR 30 DAYS FREE TRIAL NOW!
                </a>
              </div> */}

              <BoxLeftButton>
                <TrialLink1
                  onClick={(e) => {
                    // To stop the page reloading
                    // e.preventDefault()
                    // Lets track that custom click
                    trackCustomEvent({
                      // string - required - The object that was interacted with (e.g.video)
                      category: "start your free trial  button in navbar ",
                      // string - required - Type of interaction (e.g. 'play')
                      action: "Click",
                      // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                      label: "Start your free trial button in navbar",
                      // number - optional - Numeric value associated with the event. (e.g. A product ID)
                      value: 43,
                    })
                    //... Other logic here
                  }}
                  href={`${loginUrl}/register/Option`}
                  target="_blank"
                >
                  Start Free Trial
                </TrialLink1>
              </BoxLeftButton>
            </div>
          </div>
        </LandingScreen>

        <div className="contact-map-wrapper">
          <div className="contact-container">
            <div>
              {/* <div className="landing-button_forMobile">
                <a
                  onClick={(e) => {
                    trackCustomEvent({
                      category: "start your free trial caraousel button ",

                      action: "Click",

                      label: "Start your free trial button in home page",

                      value: 43,
                    })
                  }}
                  className="banner_buttons"
                  href={`${loginUrl}/register/Option`}
                  target="_blank"
                >
                  Start your 30 days free trial NOW!
                </a>
              </div> */}
              <div>
                <h1
                  style={{
                    color: "DEEPSKYBLUE",
                    textAlign: "center",
                    fontSize: "1.3750em",
                    fontFamily: "Roboto",
                  }}
                >
                  GET IN TOUCH WITH US
                </h1>
                <p
                  style={{
                    textAlign: "left",
                    fontSize: "0.8750em",
                    fontFamily: "Roboto",
                  }}
                >
                  We look forward to your feedback, reviews, inputs on features
                  and more to make Korero better!
                </p>
              </div>
              <div className="contact-form">
                <div className="contact-form-field">
                  <input
                    style={{
                      height: "2.8125em",
                      padding: "0 	0.9375em!important",
                      fontFamily: "Roboto",
                      width: "100%",
                      fontSize: "	0.9375em",
                      backgroundColor: "whitesmoke",
                      border: "1px solid silver",
                    }}
                    type="text"
                    name="name"
                    color="grey"
                    placeholder="Name "
                    onChange={this.changeHandler}
                    value={name}
                  />
                </div>
                <br />

                <div className="contact-form-field">
                  <input
                    style={{
                      height: "2.8125em",
                      padding: "0 	0.9375em!important",
                      fontFamily: "Roboto",
                      width: "100%",
                      fontSize: "	0.9375em",
                      backgroundColor: "whitesmoke",
                      border: "1px solid silver",
                    }}
                    type="text"
                    name="email"
                    placeholder="Email "
                    onChange={this.changeHandler}
                    value={email}
                  />
                </div>
                <br />
                <div className="contact-form-field">
                  <input
                    style={{
                      height: "2.8125em",
                      // padding: "0 15px!important",
                      fontFamily: "normal",
                      fontFamily: "Roboto",
                      width: "100%",
                      fontSize: "	0.9375em",
                      backgroundColor: "whitesmoke",
                      border: "1px solid silver",
                    }}
                    placeholder="Phone #"
                    type="text"
                    name="phone"
                    onChange={this.changeHandler}
                    value={phone}
                  />
                </div>
              </div>
              {/* <br /> */}
              <br />
              <div
                className="contact-form"
                // style={{
                //   display: "flex",
                //   width: "100%",
                //   justifyContent: "space-between",
                // }}
              >
                <div className="contact-form-field">
                  {" "}
                  <input
                    style={{
                      width: "48%",
                      height: "2.75em",
                    }}
                    type="text"
                    name="companyName"
                    placeholder="Company's Name "
                    onChange={this.changeHandler}
                    style={{
                      // padding: "15px",
                      height: "2.75em",
                      width: "100%",
                      fontFamily: "Roboto",
                      fontSize: "	0.9375em",
                      backgroundColor: "whitesmoke",
                      border: "1px solid silver",
                    }}
                    value={companyName}
                  />
                </div>
                <br />
                <div className="contact-form-field">
                  <select
                    name="intrestIn"
                    onChange={this.changeHandler}
                    required
                    style={{
                      padding: "0.7500em",

                      backgroundColor: "whitesmoke",
                      border: "1px solid silver",
                      width: "100%",
                      fontSize: "	0.9375em",
                      fontFamily: "Roboto",
                    }}
                  >
                    <option value="" disabled selected hidden>
                      Interested in
                    </option>
                    <option value="Schedule a demo " class="others">
                      Schedule a demo{" "}
                    </option>
                    <option value="Explore On premise option" class="others">
                      Explore On premise option
                    </option>
                    <option
                      value=" Integration with 3rd party apps "
                      class="others"
                    >
                      {" "}
                      Integration with 3rd party apps{" "}
                    </option>
                    <option value="Product development" class="others">
                      Product development
                    </option>
                    <option value="Know more about Features" class="others">
                      Know more about Features
                    </option>
                    <option value="Feedback" class="others">
                      Feedback{" "}
                    </option>
                  </select>
                </div>
                <br />
                <div
                  className="contact-form-field"
                  style={{ borderColor: "whitesmoke" }}
                >
                  <select
                    name="country"
                    onChange={this.changeHandler}
                    required
                    style={{
                      padding: "0.7500em",
                      backgroundColor: "whitesmoke",
                      border: "1px solid silver",
                      width: "100%",
                      fontSize: "	0.9375em",
                      fontFamily: "Roboto",
                    }}
                  >
                    {this.state.countries.map((country) => {
                      return (
                        <>
                          <option value="" disabledselected hidden>
                            Country
                          </option>
                          <option value={country.countryName} class="others">
                            {country.countryName} {`${country.countryDialCode}`}
                          </option>
                        </>
                      )
                    })}
                  </select>
                </div>
              </div>
              <br />

              <textarea
                style={{
                  padding: 10,

                  width: "100%",
                  height: "50%",
                  backgroundColor: "whitesmoke",
                  fontFamily: "Roboto",
                  border: "1px solid lightblue",
                  fontSize: "	0.9375em",
                }}
                name="message"
                placeholder="Message............"
                onChange={this.changeHandler}
                value={message}
                // name="input_3"
                // id="input_9_3"
                // class="textarea medium"
                // aria-describedby="gfield_description_9_3"
                // aria-required="true"
                // aria-invalid="false"
                rows="5"
                cols="50"
              ></textarea>
              <button
                style={{
                  // float: "right",
                  // width: "150px",
                  // backgroundColor: "black",
                  // color: "orange",
                  // marginLeft: "150px",
                  // marginTop: "10px",
                  // height: "5vh",
                  border: "none",
                  display: "flex",
                  justifyContent: "center",
                  textDecoration: "none !important",
                  fontFamily: "Roboto",
                  // padding: "	0.6875em 	1.2500em",
                  background: "#ee6352",
                  borderRadius: " 0.25em",
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "100",
                  fontSize: "1.25em",
                  color: "#ffffff ",
                  float: "right",
                }}
                onClick={this.submitHandler}
                // disabled
              >
                SUBMIT
              </button>
            </div>
          </div>

          <br />
          <div className="map-container">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m22!1m12!1m3!1d935.5225293577041!2d85.8228056623873!3d20.29653667718596!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m7!1i0!3e6!4m0!4m3!3m2!1d20.2970249!2d85.8232101!5e0!3m2!1sen!2sin!4v1430813318527"
              frameBorder="0"
              style={{ height: "100%", width: "100%" }}
            ></iframe>
          </div>

          <div
            className="address-container"
            style={{ backgroundColor: "black" }}
          >
            <div
              style={{
                color: "orange",
                fontSize: 20,
                fontWeight: "bold",
                marginBottom: 10,
                fontFamily: "Roboto",
                textAlign: "center",
                marginTop: 50,
              }}
            >
              {/* WE LOOK FORWARD TO HEARING FROM YOU! */}
            </div>
            <br />

            <div className="contact_info_main_box_contact">
              <div className="sub_contact_info_main_box">
                <div className="sub_contact_info_icon">
                  {" "}
                  <i class="fa fa-envelope" style={{ color: "orange" }} />
                </div>
                <div class="spacer_left"></div>
                <div className="sub_contact_info_text">
                  support@tekorero.com
                </div>
              </div>
              <br />
              <div className="sub_contact_info_main_box">
                <div className="sub_contact_info_icon">
                  <i class="fa fa-phone" style={{ color: "orange" }} />
                </div>
                <div class="spacer_left"></div>
                <div className="sub_contact_info_text">
                  +31-683532012 <br />
                  +91-7846892841
                </div>
              </div>
              <br />
              <div className="sub_contact_info_main_box">
                <div className="sub_contact_info_icon">
                  <i class="fa fa-whatsapp" style={{ color: "orange" }} />
                </div>
                <div class="spacer_left"></div>
                <div className="sub_contact_info_text">
                  {" "}
                  +31-683532012 <br />
                  +91-7846892841
                </div>
              </div>
              <br />
              <div className="sub_contact_info_main_box">
                <div className="sub_contact_info_icon">
                  <i
                    class="fa fa-map-marker"
                    style={{
                      color: "orange",
                    }}
                  />
                </div>
                <div class="spacer_left"></div>
                <div className="sub_contact_info_text">
                  Rotterdam, The Netherlands, 2662CT
                  <br /> Bhubaneswar, Odisha, India, 751013
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>

        {/* <div className="container-3">
          <Signup />
        </div> */}
        {/* <div className="footer"></div> */}
      </div>
    )
  }
}

export default ContactPage

const BoxLeftButton = styled.div`
  /* padding: 20px 260px; */
  display: flex;
  justify-content: center;
`
const TrialLink1 = styled.a`
  /* display: inline-block; */
  text-decoration: none !important;
  font-family: "Roboto";
  /* padding: 0.6875em 1.25em; */
  padding: 11px 20px;

  background: #ee6352;
  border-radius: 0.25em;
  font-family: Roboto;
  font-style: normal;
  font-weight: 100;
  font-size: 1.25em;
  /* width: 148px;
  height: 58px; */
  color: #ffffff !important;
`
const Box = styled.div`
  display: flex;
  justify-content: center;

  flex-direction: column;
`
const Header = styled.h2`
  font-family: "Roboto";
  font-weight: 400;
  font-size: 2.5em;
  text-align: center;

  letter-spacing: -0.03125em;
  color: #080612;
`
const Paragraph = styled.p`
  font-family: "Roboto";
  font-size: 1.3em;
  text-align: center;
  color: #6b6a71;
  padding: 0.625em 3.75em;
  font-weight: 100;
`
